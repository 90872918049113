<template>
  <article
    class="in-product position-relative"
    :style="lowOpacity ? 'opacity: 0.7' : ''"
  >
    <template v-if="product && product.major_final_price">
      <DiscountTag :final-price="product.major_final_price" />
    </template>
    <span v-if="checkFreeShipMent()" id="shipment_free">
      ارسال رایگان
    </span>
    <div
      v-if="product && comparable"
      @click="$store.commit('front/toggleCompare', { product, $root })"
      class="compare-btn"
      :class="{ compareBtnWithFreeShipping: checkFreeShipMent() == true }"
      :selected="isComparing"
    >
      مقایسه
      <Tick class="mr-1" :value="isComparing" />
    </div>
    <router-link
      :to="
        product
          ? {
              name: 'product.detail',
              params: { id: product.id, slug: product.slug },
            }
          : ''
      "
      class="text-center"
    >
      <!--      <div class="recommended-products-img text-center" :style="!product ? 'background-image: radial-gradient(#f4f4f4,#f4f4f4,#f4f4f4)' : ''">-->
      <div class="recommended-products-img text-center">
        <img
          loading="lazy"
          v-if="product && mainImage"
          class=""
          :src="getImage(mainImage, 'lg')"
          :alt="product.title"
        />
        <img
          loading="lazy"
          v-else
          class="skeleton-image"
          :alt="product ? product.title : ''"
        />
        <span
          class="showQuantity"
          v-if="product && product.quantity && product.quantity < 5"
        >
          {{ `تنها ${product.quantity} عدد در انبار باقي مانده است` }}
        </span>
      </div>

      <div
        v-if="product"
        class="text-center position-relative"
        id="productDescription"
      >
        <div
          class="product-rate d-flex align-items-center justify-content-center"
        >
          <!--          <b-form-rating :value="product.rate" disabled size="sm" />-->
          <!--          <span class="fontsize11 text-color-999">(<span>{{product.rate}}</span>)</span>-->
        </div>
        <h6
          :title="product.title"
          class="ProductName text-color-444 weight-bold fontsize-medium mb-0 mt-2 mt-sm-3"
        >
          {{ product.title.substr(0, 29) }}
          {{ product.title.length > 29 ? "..." : "" }}
        </h6>
        <div v-if="product.status == 'available'">
          <template v-if="major_final_price">
            <span class="text-color-themeGreen fontsize-medium">
              {{ getPrice(major_final_price) | price }}
            </span>
            <span class="text-color-666 fontsize-small pr-1">تومان</span>
            <span v-if="getDiscount(major_final_price)" class="pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="currentColor"
                class="bi bi-chevron-double-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill="#aaa"
                  fill-rule="evenodd"
                  d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
                />
                <path
                  fill="#aaa"
                  fill-rule="evenodd"
                  d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
              <span class="line-through text-color-999 fontsize-small">{{
                getDiscount(major_final_price) | price
              }}</span>
            </span>
          </template>
        </div>
        <div v-else class="hide-by-hover ">
          <span
            v-if="product.status == 'out_of_stock'"
            class="text-color-themeRed fontsize14"
          >
            ناموجود
          </span>
          <span
            v-if="product.status == 'soon'"
            class="text-color-themeRed fontsize14"
          >
            به زودی
          </span>
        </div>
      </div>
      <div v-else class="mt-3 px-4 px-sm-5">
        <b-skeleton />
        <b-skeleton class="mt-2" />
      </div>
    </router-link>
    <!-- <div class="counterDown">
      <flip-countdown
        countdownSize="1.35rem"
        v-if="product && homeData && product.discount_until != null"
        :deadline="getExpireDate()"
        :labels="{
          days: 'روز',
          hours: 'ساعت',
          minutes: 'دقیقه',
          seconds: 'ثانیه',
        }"
      />
    </div> -->
  </article>
</template>

<script>
import { BFormRating, BSkeleton } from "bootstrap-vue";
import { getImages, sortVarieties, getImage } from "@/libraries/functions";
import DiscountTag from "./DiscountTag";
import Tick from "@/parts/Front/components/shared/Tick";
// import FlipCountdown from "vue2-flip-countdown";
import moment from "moment";
export default {
  name: "Product",
  components: {
    Tick,
    DiscountTag,
    BFormRating,
    BSkeleton,
    moment,
  },
  props: {
    product: Object,
    lowOpacity: Boolean,
    comparable: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  methods: {
    getPrice(major_final_price) {
      if (major_final_price.customer_fake_price) {
        return major_final_price.sell_price_discount;
      } else if (
        major_final_price.sell_price_discount &&
        major_final_price.sell_price_discount > major_final_price.sell_price
      ) {
        return major_final_price.sell_price_discount;
      } else if (
        major_final_price.sell_price_discount &&
        major_final_price.sell_price_discount < major_final_price.sell_price
      ) {
        return major_final_price.sell_price_discount;
      } else {
        return major_final_price.sell_price;
      }
    },
    getDiscount(major_final_price) {
      if (major_final_price.customer_fake_price) {
        return major_final_price.customer_fake_price;
      } else if (
        major_final_price.sell_price_discount &&
        major_final_price.sell_price_discount > major_final_price.sell_price
      ) {
        return 0;
      } else if (
        major_final_price.sell_price_discount &&
        major_final_price.sell_price_discount < major_final_price.sell_price
      ) {
        return major_final_price.sell_price;
      } else {
        return 0;
      }
    },
    getImage,
    getProductInFlashes() {
      let products = [];
      this.homeData.flashes.forEach((flash) => {
        products.push(...flash.products);
      });
      return products;
    },
    checkFreeShipMent() {
      if (this.$route.path == "/") {
        if (
          this.product &&
          this.product.pivot &&
          this.product.pivot.shipment_free == 1
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.homeData != null) {
          if (this.homeData.flashes.length) {
            let res = false;

            let flashesProduct = this.getProductInFlashes();
            console.log(flashesProduct);
            if (flashesProduct.length) {
              flashesProduct.forEach((fp) => {
                console.log(fp.id);
                console.log(this.product.id);
                if (fp.id == this.product.id) {
                  res = true;
                  console.log("inja");
                }
              });
            }
            return res;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },
    getExpireDate() {
      // let now=this.$store.getters["front/getHomeData"]?this.$store.getters["front/getHomeData"].now:new Date();
      // let until=this.product.discount_until;
      // let between=Date.parse(until)-Date.parse(now);
      // console.log(Date.parse(until));
      // console.log(Date.parse(now));
      // console.log(between);
      // return this.product.discount_until;
    },
  },
  computed: {
    major_final_price() {
      return this.product ? this.product.major_final_price : null;
    },
    // mainPrice() {
    //   return this.product ? this.product.sell_price : null;
    // },
    // discountAmount() {
    //   return this.product ? this.product.sell_price_discount : null;
    // },
    mainImage() {
      return this.product ? this.product.major_image : null;
    },
    isComparing() {
      return Boolean(
        this.$store.getters["front/getCompares"].find(
          (i) => i.id == this.product.id
        )
      );
    },
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
  },
  created() {},
};
</script>

<style scoped>
.skeleton-image {
  width: 308px;
  display: inline-block;
  background-image: radial-gradient(
    rgb(244, 244, 244),
    rgb(244, 244, 244),
    rgb(244, 244, 244)
  );
}

.compare-btn {
  z-index: 2;
  position: absolute;
  right: 3px;
  top: 10px;
  background: white;
  border-radius: 4px;
  padding: 1px 9px;
  border: 1px solid #7570b3;
  font-size: 13px;
  transition: 200ms all;
  cursor: pointer;
}
.compare-btn.compareBtnWithFreeShipping {
  top: 30px;
}
.compare-btn:not([selected]) {
  opacity: 0;
}

.compare-btn[selected] {
  opacity: 1 !important;
}

.in-product:hover .compare-btn {
  transition: 200ms all;
  opacity: 0.6;
  pointer-events: unset;
}
.compare-btn input {
  transform: translateY(3px);
}
.compare-btn input:hover {
  cursor: pointer;
}

.compare-btn-mobile {
  width: 35px;
  height: 35px;
  transition: all 0.3s;
  background: #555555;
  border-radius: 50%;
  padding: 7px;
  margin-right: 8px;
}
.compare-btn-mobile[selected] {
  background: var(--color-themeBlue);
}
</style>
<style>
.product-rate .b-rating.disabled,
.b-rating:disabled {
  background-color: unset !important;
}
.product-rate .form-control {
  padding: 0;
  border: unset !important;
  width: 130px;
  direction: ltr;
}
.product-rate .b-rating .b-rating-star {
  padding: 0;
}

.show-by-hover.recommended-title-hover {
  position: absolute;
  bottom: 0;
}

.py-25 {
  padding: 14px 0;
}
span#shipment_free {
  position: absolute;
  left: 0;
  background: green;
  z-index: 10000;
  color: white;
  padding: 5px 10px;
  top: 0;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  font-size: 13px;
}
/* .flip-clock {
  direction: ltr;
}
.flip-card__top,
.flip-card__bottom,
.flip-card__back-bottom,
.flip-card__back::before,
.flip-card__back::after {
  background-color: #f5c8dd !important;
  color: #000 !important;
}
.flip-card__bottom,
.flip-card__back-bottom,
.flip-card__bottom-4digits,
.flip-card__back-bottom-4digits {
  background-color: #a9939d !important;
  color: #000 !important;
} */

@media screen and (max-width: 768px) {
  .compare-btn:not([selected]) {
    opacity: 0.7;
  }
  .recommended-content-item article:hover .hide-by-hover {
    opacity: 1 !important;
  }
}
span.showQuantity {
  position: absolute;
  left: 0;
  bottom: 24px;
  width: 100%;
  background: #c40050;
  color: white;
}
</style>
